<template>
  <div class="show-file-content">
    <div class="file-content-header">
      <div class="data-file">
        <Pdf v-if="['pdf'].includes(form.type)" class="pdf-icon" />
        <Jpg
            v-else-if="['jpg', 'jpeg', 'jps'].includes(form.type)"
            class="jpg-icon"
        />
        <Png v-else-if="['png'].includes(form.type)" class="png-icon" />
        <div>
          <div class="file-name-wrapper">
            <div
                class="file-name"
                :contenteditable="true"
                @blur="e => changeName(index, e.target.innerText)"
            >
              {{ form.custom_name }}
            </div>
          </div>
          <span class="edit-label">(Clique no nome para editar)</span>
        </div>
      </div>
      <div class="right-options">
        <Close class="close-icon" @click="removeFile(index)" v-if="!sent" />
      </div>
    </div>

    <b-progress
        v-if="form.progress"
        class="progress"
        :max="100"
        show-progress
        :animated="form.progress !== 100"
        :variant="!form.error ? 'primary' : 'danger'"
    >
      <b-progress-bar :value="form.progress">
        <span>
          <strong>{{ form.progress }}%</strong>
        </span>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
export default {
  name: 'AttachmentFile',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    sent: {
      type: Boolean,
      default: false
    },
    removeFile: Function,
    changeName: Function,
  },
  components: {
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Jpg: () => import('@/assets/icons/jpg.svg'),
    Png: () => import('@/assets/icons/png.svg'),
    // Check: () => import('@/components/General/Check'),
    Close: () => import('@/assets/icons/close.svg')
  },
  methods: {

  },
  computed: {

  },
}
</script>

<style lang="scss">
.show-file-content {
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 10px;

  .file-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .data-file {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .pdf-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      .jpg-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      .png-icon {
        width: 42px !important;
        height: 42px !important;
        margin-left: -5px;
      }

      #data-file-text {
        margin-left: 16px;
        width: 40ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close-icon {
      height: 24px;
      width: 24px;
      fill: var(--neutral-500);
    }
  }

  .laudable-forms {
    padding-top: 15px;
  }

  .file-name-wrapper {
    cursor: default !important;
  }
}
</style>
